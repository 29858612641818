a.skip-main{
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

a.skip-main:focus {
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 16px;
    color: var(--color);
    left: auto;
    top: auto;
    width: 100%;
    height: auto;
    overflow: auto;
    padding: 19.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 999;
}